import request from '@/utils/request.js'

// 地区列表
export function districtListApi(data) {
  return request({
    url: '/doftec/admin-pca',
    method: 'post',
    data: data
  })
}

// 地区新增
export function districtAddApi(data) {
  return request({
    url: '/doftec/admin-pca/create',
    method: 'post',
    data: data
  })
}

// 地区更新
export function districtUpdateApi(data) {
  return request({
    url: '/doftec/admin-pca/update.u',
    method: 'post',
    data: data
  })
}

// 地区删除/修改状态 操作状态：delete 删除 push 推送 download 下载
export function districtDeleteApi(data) {
  return request({
    url: '/doftec/admin-pca/action',
    method: 'post',
    data: data
  })
}